import React, { useLayoutEffect, useMemo } from "react";
import Moveable from "react-moveable";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { getClipObject } from "./lib/ables/cropFunctions";
import { createLogger, getSVGPadding } from "../../utils";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import CropRotatable from "./lib/ables/CropRotatable";
import CropResizable from "./lib/ables/CropResizable";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { OBJECT_TYPE_PANORAMIC } from "../../const";
import { useLessonPagesState } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { percentageToValue, valueToPercentage } from "../../utils/Conversion";
import { useObjectPosition } from "../../hooks/useObjectPosition";

const log = createLogger("AbstractMoveable", { color: "blue" });
const targetIdMap = new WeakMap();

export function AbstractMoveable({ pageDims }: any) {
  const objectsState = useObjectsState();
  const selectedObjectState = useSelectedObjectState();
  const { viewportDOMElementHeight, viewportDOMElementWidth } = useMovableElementsPlaneState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const isCropping = objectsState.isCropping;
  const selectedObject = objectsState.selectedObjects[0];
  const [localKeepAspectRatio, setLocalKeepAspectRatio] = React.useState<boolean | null>(null);
  const {
    rotation: finalRotation,
    position: [framedX, framedY],
    size: [framedWidth, framedHeight],
  } = useObjectPosition(
    selectedObject?.objectId,
    selectedObject?.top,
    selectedObject?.left,
    selectedObject?.width,
    selectedObject?.height,
    selectedObject?.rotation,
    selectedObject?.opacity,
  );
  const [tl] = useTimeline();
  const [miscUI] = useMiscUI();
  const moveableRef = React.useRef<any>(null);
  const { selectedPanel } = useLessonPagesState();
  const isFreeFormPoly = objectsState.selectedObjects[0]?.type === "freeFormPoly";
  // mapping objects, expensive. consider memoizing
  const guides = objectsState.objectList.map((object) => {
    return `[data-objectid="${object.objectId}"]`;
  });
  const hasClipCoordinates = (object: any) => {
    if (object && ("clipPath" in object || "clipPathString" in object)) {
      if (object.clipPathString) {
        return true;
      } else if (object.clipPath) {
        return !Object.values(object.clipPath).every((value) => value === 0);
      }
    }
    return false;
  };

  const isCroppedImage = hasClipCoordinates(objectsState.selectedObjects[0]);

  const selected = objectsState.selectedObjects
    // .filter((x) => !(x.type?.includes("Arrow") || x.type?.includes("Line")))
    .map((object) => {
      if (isCroppedImage && isCropping) {
        return `[data-ref="${object.objectId}"]`;
      }
      return `[data-objectid="${object.objectId}"]`;
    });

  const hasRotation = useMemo(() => {
    if (objectsState.selectedObjects.length > 0) {
      if (
        objectsState.selectedObjects[0]?.type &&
        // TODO: rollback adding the "video", "table", "SCORM" types
        [OBJECT_TYPE_PANORAMIC].includes(objectsState.selectedObjects[0]?.type)
      ) {
        return false;
      }
    }
    return true;
  }, [objectsState]);

  if (objectsState.selectedObjects.length > 1) {
    selected.filter((x) => !(x.type?.includes("Arrow") || x.type?.includes("Line")));
  }

  const determineAnimatedObjectOutOfBoundaries = (objectId: string) => {
    const animatedObject = objectsState.animatedObjects.find((object) => object.id === objectId);
    if (animatedObject) {
      return (
        tl.scrubbingCurrentTime < animatedObject.start ||
        tl.scrubbingCurrentTime > (animatedObject.end ?? tl.sequenceLength)
      );
    }
  };

  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });

  const className = (isDisplayed: boolean, grayBorderIsOn: boolean) => {
    const selectedObjectType = objectsState.selectedObjects[0] ? objectsState.selectedObjects[0]?.type : null;
    return [
      `${typeof isDisplayed === "undefined" || isDisplayed ? "" : "target-not-displayed"} ${grayBorderIsOn}`,
      selectedObjectType === OBJECT_TYPE_PANORAMIC && miscUI.objectLocked ? "panoramic-moveable" : "",
      selectedObjectType === "smartObject" && selectedPanel === "advanced" ? "smartobject-moveable" : "",
      selectedObjectType === "freeFormPoly" && selectedPanel === "advanced" ? "advanced-moveable" : "",
    ].join(" ");
  };
  const getRenderDirections = () => {
    if (
      objectsState.selectedObjects[0]?.type?.includes("Arrow") ||
      objectsState.selectedObjects[0]?.type?.includes("Line")
    ) {
      return ["e", "w"];
    }

    if (isCroppedImage) {
      return ["nw", "n", "ne", "e", "sw", "s", "se", "w"];
    }
    return ["nw", "ne", "sw", "se"];
  };

  const canResize = () => {
    if (isCroppedImage && isCropping) {
      return true;
    }
    if (isCroppedImage) {
      return true;
    }
    return true;
  };

  const getLockAspectRatio = () => {
    if (isCropping && localKeepAspectRatio === false) {
      return false;
    }
    return objectsState.selectedObjects[0]?.lockAspectRatio ?? false;
  };

  const renderDirections = getRenderDirections();
  return (
    <Moveable
      target={selected}
      ref={(ref) => {
        moveableRef.current = ref;
        if (ref) {
          objectsDispatch({
            type: ObjectActionsType.SET_MOVEABLE_OBJECT_REF,
            moveableRef: ref,
          });
        }
      }}
      renderDirections={renderDirections}
      checkInput
      resizable={canResize()}
      throttleResize={0}
      draggable={!miscUI.objectLocked && !isCropping}
      scalable={!miscUI.objectLocked}
      rotatable={hasRotation && !miscUI.objectLocked}
      snappable={false}
      elementGuidelines={guides}
      triggerAblesSimultaneously={false}
      clipTargetBounds={true}
      clippable={isCropping}
      cropresizable={isCroppedImage}
      cropscalable={isCroppedImage}
      ables={isCroppedImage ? [CropResizable, CropRotatable] : []}
      props={{
        cropresizable: isCroppedImage,
      }}
      clipRelative={false}
      clipArea={false}
      dragWithClip={true}
      defaultClipPath={"inset"}
      keepRatio={getLockAspectRatio()}
      originRelative={true}
      // originDraggable={true}
      stopPropagation={true}
      className={className(true, false)}
      onRenderStart={({ inputEvent }) => {
        if (inputEvent) {
          inputEvent.stopPropagation();
        }
      }}
      // onDragOrigin={({ target, transformOrigin, afterTransform, datas }) => {
      //   target.style.transformOrigin = transformOrigin;
      //   target.style.transform = afterTransform;
      //   datas.transformOrigin = transformOrigin;
      // }}
      // onDragOriginEnd={({ target, datas }) => {
      //   if (!target.dataset.objectid) return;
      //
      //   objectsDispatch({
      //     type: ObjectActionsType.UPDATE_TRANSFORM_ORIGIN,
      //     payload: {
      //       transformOrigin: datas.transformOrigin,
      //       objectId: target.dataset.objectid,
      //     },
      //   });
      // }}
      onRotateStart={({ target, clientX, clientY, datas }) => {
        // setFixedPosition([1500, 648]);
        if (isCroppedImage) {
          datas.originalElementWhenCropped = document.querySelector(`[data-ref="${selectedObject.objectId}"]`);
          const {
            left: parentLeft,
            top: parentTop,
            width: parentWidth,
            height: parentHeight,
          } = datas.originalElementWhenCropped?.getBoundingClientRect();
          const {
            left: childLeft,
            top: childTop,
            width: childWidth,
            height: childHeight,
          } = target.getBoundingClientRect();

          const originX = (childLeft - parentLeft + childWidth / 2) / parentWidth;
          const originY = (childTop - parentTop + childHeight / 2) / parentHeight;
          if (!selectedObject.transformOrigin && (selectedObject.rotation ?? 0) === 0) {
            datas.originalElementWhenCropped.style.transformOrigin = `${originX * 100}% ${originY * 100}%`;
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT,
              payload: {
                objectId: selectedObject.objectId,
                object: {
                  transformOrigin: { x: originX * 100, y: originY * 100 },
                },
              },
            });
          }
        }

        // e.setFixedDirection([-0.5, -0.5]);
      }}
      onRotate={({ target, rotate, transform, datas, delta, ...rest }) => {
        if (isCroppedImage) {
          const newRotation = delta + (finalRotation ?? 0);
          console.log("newRotation", newRotation);
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              rotation: newRotation,
            },
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_ROTATION,
            payload: newRotation,
          });
          datas.rotation = newRotation;
        } else {
          datas.rotation = rotate;
          target.style.transform = transform;
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_ROTATION,
            payload: rotate,
          });
        }
      }}
      onRotateEnd={({ datas, target }) => {
        if (isCroppedImage) {
        } else {
          const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
          if (outOfTimeBoundaries) {
            return (target.style.transform = datas.initialTransform);
          }
          if (typeof datas.rotation === "number") {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                rotation: datas.rotation,
              },
            });
          }
        }
      }}
      onResizeStart={({ target, datas }) => {
        datas.initialTransform = target.style.transform;
        datas.width = target.clientWidth;
        datas.height = target.clientHeight;
        datas.initialWidth = target.clientWidth;
        datas.initialHeight = target.clientHeight;

        if (isFreeFormPoly) {
          const ffAnnotation = objectsState.selectedObjects[0];
          const points = ffAnnotation.points;
          const padding = getSVGPadding(ffAnnotation.strokeWidth, datas.width, datas.height);
          const percentPoints = points.map((point) => {
            return {
              ...point,
              xPercent: ((point.x - padding) / (datas.width - 2 * padding)) * 100,
              yPercent: ((point.y - padding) / (datas.height - 2 * padding)) * 100,
            };
          });
          datas.percentPoints = percentPoints;
        }
        if (isCroppedImage && !isCropping) {
          datas.originalElementWhenCropped = document.querySelector(`[data-ref="${selectedObject.objectId}"]`);
          const {
            left: childLeft,
            top: childTop,
            width: childWidth,
            height: childHeight,
          } = target.getBoundingClientRect();
          console.log("childLeft", childLeft);
          datas.startingChildLeft = childLeft;
          datas.startingChildTop = childTop;
          datas.startingChildWidth = childWidth;
          datas.startingChildHeight = childHeight;
          datas.startingWidth = framedWidth;
          datas.startingHeight = framedHeight;
          datas.xAcc = 0;
          datas.yAcc = 0;
          datas.isPortrait = framedHeight > framedWidth;
          datas.ratio = framedHeight / framedWidth;
          datas.startingX = framedX;
          datas.startingY = framedY;
          datas.newFramedX = framedX;
          datas.newFramedY = framedY;
        } else {
          datas.initialTransform = target.style.transform;
          datas.width = target.clientWidth;
          datas.height = target.clientHeight;
        }
      }}
      onResize={({ target, width: newWidth, height: newHeight, drag, datas }) => {
        if (selectedPanel === "advanced" && !isFreeFormPoly) return null;
        if (isFreeFormPoly) {
          if (newWidth < 50 || newHeight < 50) {
            return;
          }
        }

        if (isCroppedImage && !isCropping) {
          // get the new dimensions of the faux div
          console.log("newWidth", newWidth, "newHeight", newHeight);
          const leftPercentage = selectedObject.clipPath.left;
          const rightPercentage = selectedObject.clipPath.right;
          const topPercentage = selectedObject.clipPath.top;
          const bottomPercentage = selectedObject.clipPath.bottom;
          const {
            left: childLeft,
            top: childTop,
            width: childWidth,
            height: childHeight,
          } = target.getBoundingClientRect();
          const childXMovement = childLeft - datas.startingChildLeft;
          const childYMovement = childTop - datas.startingChildTop;
          console.log("childLeft", childLeft, "childTop", childTop);
          console.log(
            "datas.startingChildLeft",
            datas.startingChildLeft,
            "datas.startingChildTop",
            datas.startingChildTop,
          );
          console.log("childXMovement", childXMovement, "childYMovement", childYMovement);
          const clipWidthPercentage = 100 - leftPercentage - rightPercentage;
          const clipHeightPercentage = 100 - topPercentage - bottomPercentage;
          const newParentWidth = newWidth / (clipWidthPercentage / 100);
          const newParentHeight = newHeight / (clipHeightPercentage / 100);
          console.log("W", newParentWidth, "H", newParentHeight);
          const percentWidth = valueToPercentage(newParentWidth, viewportDOMElementWidth || 0);
          const percentHeight = valueToPercentage(newParentHeight, viewportDOMElementHeight || 0);

          const childXMovementPercentage = valueToPercentage(childXMovement, viewportDOMElementWidth || 0);
          const childYMovementPercentage = valueToPercentage(childYMovement, viewportDOMElementHeight || 0);

          const normalizedXMovement = percentageToValue(childXMovementPercentage, viewportDOMElementWidth || 0);
          const normalizedYMovement = percentageToValue(childYMovementPercentage, viewportDOMElementHeight || 0);

          console.log("normalizedXMovement", normalizedXMovement, "normalizedYMovement", normalizedYMovement);
          const newX = framedX - normalizedXMovement;
          const newY = framedY - normalizedYMovement;
          datas.newFramedX = newX;
          datas.newFramedY = newY;

          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              width: percentWidth,
              height: percentHeight,
              x: newX,
              y: newY,
            },
          });
        } else {
          target.style.width = `${newWidth}px`;
          target.style.height = `${newHeight}px`;
          target.style.transform = drag.transform;
          const beforeTranslate = drag.beforeTranslate;
          const oldWidth = datas.width;
          const oldHeight = datas.height;
          datas.left = beforeTranslate[0];
          datas.top = beforeTranslate[1];
          datas.width = newWidth;
          datas.height = newHeight;
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_X,
            payload: beforeTranslate[0],
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_Y,
            payload: beforeTranslate[1],
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_WIDTH,
            payload: newWidth,
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_HEIGHT,
            payload: newHeight,
          });
        }

        /**
         * LEGACY STUFF
         */
        /**
         * Triangle
         */
        if (target.firstChild?.nodeName === "polygon" && target.getAttribute("name") === "triangle") {
          target.firstChild.attributes.points.nodeValue = `4,${target.clientHeight - 4} ${
            (target.clientWidth - 2) / 2
          },4 ${target.clientWidth - 4},${target.clientHeight - 4}`;
        }
        /**
         * Ellipse
         */
        if ((target as any).firstChild.nodeName === "ellipse") {
          (target as any).firstChild.attributes.cx.nodeValue = `${target.clientWidth / 2}`;
          (target as any).firstChild.attributes.cy.nodeValue = `${target.clientHeight / 2}`;
          (target as any).firstChild.attributes.rx.nodeValue = `${target.clientWidth / 2 - 3}`;
          (target as any).firstChild.attributes.ry.nodeValue = `${target.clientHeight / 2 - 3}`;
        }
      }}
      onResizeEnd={({ datas, target }) => {
        if (isCroppedImage && !isCropping) {
        } else {
          const pixelX = datas.left;
          const pixelY = datas.top;
          const pixelWidth = datas.width;
          const pixelHeight = datas.height;
          const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
          if (outOfTimeBoundaries) {
            return (target.style.transform = datas.initialTransform);
          }
          if (typeof pixelX === "number") {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                x: pixelX,
              },
            });
          }
          log("pixelY", pixelY, "datas.deltaY", datas.deltaY);
          if (typeof pixelY === "number") {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                y: pixelY,
              },
            });
          }
          if (typeof pixelWidth === "number") {
            const percentWidth = (pixelWidth / (viewportDOMElementWidth ?? 1)) * 100;
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                width: percentWidth,
              },
            });
          }
          if (typeof pixelHeight === "number") {
            const percentHeight = (pixelHeight / (viewportDOMElementHeight ?? 1)) * 100;
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                height: percentHeight,
              },
            });
          }

          /**
           * Resize the blurCutoutShapes as the object is resized
           */

          const blurCutoutShapes = selectedObjectState.blurCutoutShapes;
          if (blurCutoutShapes && blurCutoutShapes.length > 0) {
            const horizontalDelta = datas.width / datas.initialWidth;
            const verticalDelta = datas.height / datas.initialHeight;

            const newBlurCutoutShapes = blurCutoutShapes.map((blurCutoutShape) => ({
              ...blurCutoutShape,
              x: blurCutoutShape.x * horizontalDelta,
              y: blurCutoutShape.y * verticalDelta,
              w: blurCutoutShape.w * horizontalDelta,
              h: blurCutoutShape.h * verticalDelta,
            }));

            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
              payload: newBlurCutoutShapes,
            });

            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                blurCutoutShapes: newBlurCutoutShapes,
              },
            });
          }
        }
      }}
      onDragStart={({ target, datas, clientX, clientY }) => {
        if (isCroppedImage) {
          datas.xAcc = 0;
          datas.yAcc = 0;
          datas.originalElementWhenCropped = document.querySelector(`[data-ref="${selectedObject.objectId}"]`);
          datas.initialTransform = datas.originalElementWhenCropped.style.transform;
          // framedX
          // const left = objectsState.selectedObjects[0].left;
          // const pixelLeft = percentageToValue(left, viewportDOMElementWidth || 0);
          datas.startingX = framedX;
          // const pixelTop = percentageToValue(objectsState.selectedObjects[0].top, viewportDOMElementHeight || 0);
          datas.startingY = framedY;
          datas.deltaX = 0;
          datas.deltaY = 0;
        } else {
          datas.initialTransform = target.style.transform;
          datas.deltaX = 0;
          datas.deltaY = 0;
        }
      }}
      onDrag={({ target, datas, beforeTranslate, transform, delta, inputEvent }) => {
        if (isCroppedImage) {
          const movementX = inputEvent.movementX;
          datas.xAcc += movementX;
          const movementY = inputEvent.movementY;
          datas.yAcc += movementY;
          const pixelX = datas.startingX + datas.xAcc;
          // we have to manually generate the transform string becasue we target a different element
          if (typeof pixelX === "number") {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                x: pixelX,
              },
            });
          }

          const pixelY = datas.startingY + datas.yAcc;
          if (typeof pixelY === "number") {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
              payload: {
                time: tl.scrubbingCurrentTime,
                y: pixelY,
              },
            });
          }
        } else {
          datas.deltaX += delta[0];
          datas.deltaY += delta[1];
          target.style.transform = transform;
          datas.left = beforeTranslate[0];
          datas.top = beforeTranslate[1];

          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_X,
            payload: datas.left,
          });
          selectedObjectDispatch({
            type: SelectedObjectActionTypes.SET_Y,
            payload: datas.top,
          });
        }
      }}
      onDragEnd={({ target, datas }) => {
        const topFromObjState = percentageToValue(selectedObject.top, viewportDOMElementHeight || 0);
        const leftFromObjState = percentageToValue(selectedObject.left, viewportDOMElementWidth || 0);

        console.log("onDragEnd", datas.deltaX, leftFromObjState, datas.deltaY, topFromObjState);
        if (isCroppedImage) {
          //
        } else {
          const pixelX = datas.left;
          const pixelY = datas.top;
          const outOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectsState.selectedObjects[0].objectId);
          if (outOfTimeBoundaries) {
            return (target.style.transform = datas.initialTransform);
          }
          const o = {};
          if (typeof pixelY === "number" && datas.deltaY !== 0) {
            o.y = pixelY;
          }
          if (typeof pixelX === "number" && datas.deltaY !== 0) {
            o.x = pixelX;
          }
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              ...o,
            },
          });
        }
      }}
      onDragGroupStart={({ inputEvent, targets }) => {
        inputEvent.stopPropagation();
        targets.forEach((target) => {
          targetIdMap.set(target, {
            initialTransform: target.style.transform,
          });
        });
      }}
      onDragGroup={({ events }) => {
        events.forEach(({ target, beforeTranslate, transform }) => {
          targetIdMap.get(target).left = beforeTranslate[0];
          targetIdMap.get(target).top = beforeTranslate[1];
          target.style.transform = transform;
        });
      }}
      onDragGroupEnd={({ targets }) => {
        targets.forEach((target) => {
          const { left, top, initialTransform } = targetIdMap.get(target);
          const objectId = target.getAttribute("data-objectid") ?? undefined;
          if (!objectId) return;
          const isOutOfTimeBoundaries = determineAnimatedObjectOutOfBoundaries(objectId);
          if (isOutOfTimeBoundaries) {
            return (target.style.transform = initialTransform);
          }
          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
            payload: {
              time: tl.scrubbingCurrentTime,
              x: left,
              y: top,
              objectId,
            },
          });
        });
      }}
      onClickGroup={(e) => {
        const selectedTarget = e.inputTarget;
        let elementWithObjectId: Element | null = selectedTarget;
        const maxIterations = 9;
        let selectedObjectId;
        selectedObjectId = elementWithObjectId.getAttribute("data-objectid");
        elementWithObjectId = elementWithObjectId.parentElement!;

        for (let i = 0; i < maxIterations; i++) {
          if (!selectedObjectId && elementWithObjectId) {
            selectedObjectId = elementWithObjectId.getAttribute("data-objectid");
            elementWithObjectId = elementWithObjectId.parentElement!;
          } else {
            break;
          }
        }
        if (!selectedObjectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.inputEvent.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId: selectedObjectId },
        });
      }}
      onClipStart={({ inputEvent }) => {
        inputEvent.stopPropagation();
        setLocalKeepAspectRatio(false);
      }}
      onClip={({ target, clipStyles, clipStyle, datas }) => {
        datas.clipStyle = clipStyle;
        target.style.clipPath = clipStyle;
        datas.top = parseFloat(clipStyles[0]);
        datas.right = parseFloat(clipStyles[1]);
        datas.bottom = parseFloat(clipStyles[2]);
        datas.left = parseFloat(clipStyles[3]);
      }}
      onClipEnd={({ datas, target }) => {
        setLocalKeepAspectRatio(null);
        const pixelWidth = framedWidth;
        const pixelHeight = framedHeight;
        const { top, right, bottom, left } = datas;
        // Calculate the width and height of the clipped area
        const clipWidth = pixelWidth - left - right;
        const clipHeight = pixelHeight - top - bottom;

        // Calculate the center of the clipped area
        const clipCenterX = left + clipWidth / 2;
        const clipCenterY = top + clipHeight / 2;

        const percentTop = (top / pixelHeight) * 100;
        const percentRight = (right / pixelWidth) * 100;
        const percentBottom = (bottom / pixelHeight) * 100;
        const percentLeft = (left / pixelWidth) * 100;
        const clipObject = {
          top: percentTop,
          right: percentRight,
          bottom: percentBottom,
          left: percentLeft,
        };

        const percentClipString = `inset(${percentTop}% ${percentRight}% ${percentBottom}% ${percentLeft}%)`;

        objectsDispatch({
          type: ObjectActionsType.SET_OBJECT_CLIP_PATH,
          payload: {
            clipPath: clipObject,
            clipPathString: percentClipString,
            objectId: objectsState.selectedObjects[0].objectId,
          },
        });

        const newTransformOrigin = {
          x: (clipCenterX / pixelWidth) * 100,
          y: (clipCenterY / pixelHeight) * 100,
        };
        const prevRect = target.getBoundingClientRect();
        target.style.transformOrigin = `${newTransformOrigin.x}% ${newTransformOrigin.y}%`;
        const newRect = target.getBoundingClientRect();
        const xDifference = prevRect.x - newRect.x;
        const yDifference = prevRect.y - newRect.y;

        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT,
          payload: {
            objectId: objectsState.selectedObjects[0].objectId,
            object: {
              transformOrigin: newTransformOrigin,
            },
          },
        });

        const pixelX = framedX + xDifference;

        const pixelY = framedY + yDifference;
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT_AT_TIME_FROM_MOVEMENT,
          payload: {
            objectId: objectsState.selectedObjects[0].objectId,
            time: tl.scrubbingCurrentTime,
            x: pixelX,
            y: pixelY,
          },
        });

        // Apply the updated transform origin and shift to the outer div

        target.style.clipPath = percentClipString;
      }}
    />
  );
}
