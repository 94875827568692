import { useMemo } from "react";
import { useObjectsState } from "../contexts/ObjectsProvider";
import { useAnimatedObject } from "./useAnimatedObject";
import { viewBoxZoomToZoomRatio } from "../utils";
import { useInterpolatedFrame } from "./useInterpolatedFrame";

export function useViewBox(objectId: string) {
  const animatedObject = useAnimatedObject(objectId);
  const frames = animatedObject?.frames ?? [];
  const framesCacheId = animatedObject?.framesCacheId;
  const objectsState = useObjectsState();
  const interpolated = useInterpolatedFrame(objectId);
  const object = objectsState.smartObjects.find((obj) => obj.objectId === objectId);
  const { viewBox, viewBoxZoom, viewBoxX, viewBoxY } = object;

  //TODO: optimize this
  const {
    viewBoxZoom: interpolatedViewBoxZoom,
    viewBoxX: interpolatedViewBoxX,
    viewBoxY: interpolatedViewBoxY,
  } = interpolated;
  const vb = useMemo(() => {
    if (!viewBox) return null;
    let [, , width, height] = viewBox?.split(" ")?.map(Number);
    // the ratio is the inverse of the zoom

    const z = interpolatedViewBoxZoom ?? viewBoxZoom;
    const ratio = viewBoxZoomToZoomRatio(z);
    const x2 = interpolatedViewBoxX ?? viewBoxX;
    const y2 = interpolatedViewBoxY ?? viewBoxY;
    width = width * ratio;
    height = height * ratio;
    const newViewBox = `${x2} ${y2} ${width} ${height}`;
    return newViewBox;
  }, [viewBoxZoom, viewBoxX, viewBoxY, interpolatedViewBoxZoom, interpolatedViewBoxX, interpolatedViewBoxY]);
  return {
    viewBox: vb,
    viewBoxZoom: interpolatedViewBoxZoom ?? viewBoxZoom,
    viewBoxX: interpolatedViewBoxX ?? viewBoxX,
    viewBoxY: interpolatedViewBoxY ?? viewBoxY,
  };
}
