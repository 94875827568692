import { useCallback, useState } from "react";

export function useDomRef<El = HTMLDivElement | SVGSVGElement>() {
  const [ref, setRef] = useState<El | null>(null);

  const set = useCallback((r: El | null) => {
    if (r && r !== ref) {
      setRef(r);
    }
  }, []);

  return [ref, set] as const;
}
