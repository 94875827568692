import React, { SVGProps, useEffect, useRef, useState } from "react";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { SVGAnnotationProps } from "../../Annotations";
import { HighlightAnnotation } from "../../Annotations/HighlightAnnotation";
import { handleTransparencyColor } from "../../../utils/Conversion";
import useRefAndState from "../../../hooks/useRefAndState";
import useDrag from "../../../hooks/useDrag";
import {
  LessonPagesActions,
  useLessonPagesDispatch,
  useLessonPagesState,
} from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { getSVGPadding } from "../../../utils";
import { nanoid } from "../../../lib/nanoId";
import { useUIStore } from "../../../contexts/MovableElementsPlaneProvider";

export function FreeFormPoly({
  x,
  y,
  objectId,
  onMouseDown,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  isDisplayed,
  blur,
}: SVGAnnotationProps) {
  const { annotations, selectedObjects } = useObjectsState();
  const { selectedPanel, selectedPointId } = useLessonPagesState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObject = selectedObjects[0];
  const svgRef = useRef<SVGSVGElement>(null);
  const [isCursorOnBorder, setIsCursorOnBorder] = useState(false);
  const index = annotations.findIndex((annotation: any) => annotation.objectId === objectId);
  const annotation = annotations[index];
  const id = `freeFormPoly-${index}`;
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  const padding = getSVGPadding(strokeWidth, width, height);
  const onMouseDownHandler = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement>) => {
    onMouseDown(e, objectId);
  };
  const designerViewportDims = useUIStore((s)=>{return s.designerViewportDims});
  const [isDragging, setIsDragging] = useState(false);
  const [heightBeforeResize, setHeightBeforeResize] = useState(height);
  const [widthBeforeResize, setWidthBeforeResize] = useState(width);

  const pointsToSVGPoints = (pointsArray: any) => {
    return pointsArray.map((point: any) => `${point.x},${point.y}`).join(" ");
  };
  const points = pointsToSVGPoints(annotation?.points);
  const isAdvancedMode = selectedPanel === "advanced";

  const updatePoint = ({ id, x, y }: { id: string; x: number; y: number }) => {
    const newPoints = annotation.points.map((p) => (p.id === id ? { id, x, y } : p));

    objectsDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: selectedObject.objectId,
        object: {
          points: newPoints,
        },
      },
    });
  };
  const viewBoxWidth = width;
  const viewBoxHeight = height;

  const getViewBox = () => {
    if (isAdvancedMode) {
      return `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
    } else {
      return `0 0 ${widthBeforeResize} ${heightBeforeResize}`;
    }
  };
  const viewBox = getViewBox();

  // const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
  const svg = svgRef.current?.getBoundingClientRect();
  const handlePolygonClick = (e: React.MouseEvent<SVGPolygonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isDragging) return;
    if (!isAdvancedMode || !svg) return;

    // Get the transformation matrix of the SVG element
    const svgElement = svgRef.current; // Assuming svg is a ref to the SVG element
    if (!svgElement) return;
    const point = svgElement.createSVGPoint();
    point.x = e.clientX;
    point.y = e.clientY;

    // Apply the inverse transformation to get the coordinates in the SVG's coordinate system
    const transformedPoint = point.matrixTransform(svgElement.getScreenCTM()?.inverse());

    const x = transformedPoint.x;
    const y = transformedPoint.y;

    const isCursorOnBorder = isNearBorder({ x, y }, annotation.points);
    if (!isCursorOnBorder) return;

    const id = nanoid();
    const newPoint = { id, x, y };
    const newPoints = insertPointAtCorrectPosition(annotation.points, newPoint);

    objectsDispatch({
      type: ObjectActionsType.UPDATE_OBJECT,
      payload: {
        objectId: selectedObject.objectId,
        object: {
          points: newPoints,
        },
      },
    });
  };

  function insertPointAtCorrectPosition(
    points: { id: string; x: number; y: number }[],
    newPoint: { id: string; x: number; y: number },
  ) {
    let minDistance = Infinity;
    let insertIndex = 0;

    for (let i = 0; i < points.length; i++) {
      const p1 = points[i];
      const p2 = points[(i + 1) % points.length];
      const distance = distanceToSegment(newPoint, p1, p2);

      if (distance < minDistance) {
        minDistance = distance;
        insertIndex = i + 1;
      }
    }

    const newPoints = [...points];
    newPoints.splice(insertIndex, 0, newPoint);
    return newPoints;
  }

  function distanceToSegment(
    point: { x: number; y: number },
    p1: { x: number; y: number },
    p2: { x: number; y: number },
  ) {
    const { x, y } = point;
    const { x: x1, y: y1 } = p1;
    const { x: x2, y: y2 } = p2;

    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    const param = len_sq !== 0 ? dot / len_sq : -1;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 0 && param < 1) {
      xx = x1 + param * C;
      yy = y1 + param * D;
    } else {
      xx = x2;
      yy = y2;
    }

    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

  useEffect(() => {
    if (isAdvancedMode && selectedObject?.objectId === annotation.objectId) {
        const diffWidth = width - widthBeforeResize;
        const diffHeight = height - heightBeforeResize;

        if (diffWidth !== 0 || diffHeight !== 0) {
            const ffAnnotation = selectedObject;
            const points = ffAnnotation.points;

            // Get padding for the old and new sizes
            const oldPadding = getSVGPadding(ffAnnotation.strokeWidth, widthBeforeResize, heightBeforeResize);
            const newPadding = getSVGPadding(ffAnnotation.strokeWidth, width, height);

            // Convert the existing points to percentage-based coordinates
            const percentPoints = points.map((point) => ({
                ...point,
                xPercent: ((point.x - oldPadding) / (widthBeforeResize - 2 * oldPadding)) * 100,
                yPercent: ((point.y - oldPadding) / (heightBeforeResize - 2 * oldPadding)) * 100,
            }));
            // Convert the percentage-based coordinates back to absolute coordinates with the new size
            const newPoints = percentPoints.map((point) => {
                const x = (point.xPercent * (width - 2 * newPadding)) / 100 + newPadding;
                const y = (point.yPercent * (height - 2 * newPadding)) / 100 + newPadding;
                return { id: point.id, x, y };
            });

            // Update the object with the new points
            objectsDispatch({
                type: ObjectActionsType.UPDATE_OBJECT,
                payload: {
                    objectId: ffAnnotation.objectId,
                    object: {
                        points: newPoints,
                    },
                },
            });

            // Update the previous dimensions after resizing is handled
            setHeightBeforeResize(height);
            setWidthBeforeResize(width);
        }
    }
}, [isAdvancedMode, width, height, selectedObject, annotation]);

  useEffect(() => {
    setHeightBeforeResize(height);
    setWidthBeforeResize(width);
  }, [designerViewportDims.height, designerViewportDims.width]);
  return (
    <>
      <div
        data-objectid={objectId}
        id={id}
        onMouseDown={onMouseDownHandler}
        style={{
          position: "absolute",
          width: `${width}px`,
          height: `${height}px`,
          cursor: "grab",
          transform: transformString,
          pointerEvents: "all",
          zIndex: zIndex,
          filter: isDisplayed ? "" : "grayscale(100%)",
        }}
      >
        {isAdvancedMode &&
          selectedObject?.objectId === objectId &&
          annotation.points.map((point: any, i: number) => (
            <FreeFormPoint
              isSelected={selectedPointId === point.id}
              key={point.id}
              objectId={objectId}
              maxWidth={width}
              maxHeight={height}
              x={point.x}
              y={point.y}
              id={point.id}
              index={i}
              updatePoint={updatePoint}
              rotation={rotate ?? 0}
              padding={padding}
              setIsDragging={setIsDragging}
              isDragging={isDragging}
            />
          ))}
        <svg
          ref={svgRef}
          viewBox={viewBox}
          className="annotation"
          name="freeFormPoly"
          preserveAspectRatio="xMinYMin meet"
          style={{
            width: "100%",
            height: "100%",
            pointerEvents: isAdvancedMode ? "all" : "none",
          }}
        >
          <polygon
            points={points}
            pointerEvents={isDragging ? "none" : "unset"}
            stroke={`${handleTransparencyColor(borderColor, opacity) || "white"}`}
            strokeWidth={strokeWidth}
            fill={`${handleTransparencyColor(backgroundColor, opacity) || "white"}`}
            onMouseMove={(e: React.MouseEvent<SVGPolygonElement, MouseEvent>) => {
              e.stopPropagation();
              if (!isAdvancedMode) return;
              if (!svg) return;

              // Get the transformation matrix of the SVG element
              const svgElement = svgRef.current; // Assuming svg is a ref to the SVG element
              if (!svgElement) return;
              const point = svgElement.createSVGPoint();
              point.x = e.clientX;
              point.y = e.clientY;

              // Apply the inverse transformation to get the coordinates in the SVG's coordinate system
              const transformedPoint = point.matrixTransform(svgElement.getScreenCTM()?.inverse());

              const x = transformedPoint.x;
              const y = transformedPoint.y;

              const isInLine = isNearBorder({ x, y }, annotation.points);
              if (!isInLine && isCursorOnBorder) {
                setIsCursorOnBorder(false);
              } else if (isInLine && !isCursorOnBorder) {
                setIsCursorOnBorder(true);
              }
            }}
            style={{
              cursor: isAdvancedMode && isCursorOnBorder ? "copy" : "default",
            }}
            onClick={handlePolygonClick}
          />
        </svg>
        <HighlightAnnotation objectId={objectId} />
      </div>
    </>
  );
}

interface FreeFormPointProps {
  objectId: string;
  maxWidth: number;
  maxHeight: number;
  x: number;
  y: number;
  rotation: number;
  id: string;
  padding: number;
  updatePoint: (point: { id: string; x: number; y: number }) => void;
  isSelected: boolean;
  isDragging?: boolean;
  setIsDragging: (isDragging: boolean) => void;
}

function FreeFormPoint({
  objectId,
  maxWidth,
  maxHeight,
  x,
  y,
  updatePoint,
  id,
  rotation = 0,
  padding,
  isSelected = false,
  isDragging,
  setIsDragging,
  index,
}: FreeFormPointProps) {
  const lessonPagesDispatch = useLessonPagesDispatch();
  const [ref, state] = useRefAndState<HTMLDivElement | null>(null);

  useDrag(state, {
    debugName: `Point ${x}, ${y}`,
    onDragStart(event) {
      event.stopPropagation();
      // console.log("drag start");
      setIsDragging(true);
      return {
        onDrag(dx, dy) {
          const radians = (Math.PI / 180) * rotation;
          const cos = Math.cos(radians);
          const sin = Math.sin(radians);

          const newDx = dx * cos + dy * sin;
          const newDy = dy * cos - dx * sin;

          let newX = x + newDx;
          let newY = y + newDy;

          // Ensure the point stays within the bounds
          if (newX < padding) {
            newX = padding;
          }
          if (newY < padding) {
            newY = padding;
          }
          if (newX > maxWidth - padding) {
            newX = maxWidth - padding;
          }
          if (newY > maxHeight - padding) {
            newY = maxHeight - padding;
          }

          updatePoint({ id, x: newX, y: newY });
        },
        onDragEnd(dragHappened) {
          // console.log("drag end", dragHappened);
          setIsDragging(false);
        },
      };
    },
  });

  const handlePointClick = () => {
    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_POINT_ID,
      payload: id,
    });
  };

  const circleProps: SVGProps<SVGCircleElement> = {
    r: isSelected ? "8.5" : "10",
    fill: index === 0 ? "red" : isSelected ? "white" : "#40E7FF",
    stroke: isSelected ? "#40E7FF" : "white",
    strokeWidth: isSelected ? "5" : "2",
  };

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "22px",
        height: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: `${x}px`,
        top: `${y}px`,
        transform: "translate(-50%, -50%)",
        zIndex: 100,
      }}
      onClick={handlePointClick}
    >
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" {...circleProps} />
      </svg>
    </div>
  );
}

function isNearBorder(newPoint: { x: number; y: number }, points: { x: number; y: number }[]) {
  // Adjust this value based on desired sensitivity to the border
  const tolerance = 10;

  for (let i = 0; i < points.length; i++) {
    const point1 = points[i];
    const point2 = points[(i + 1) % points.length];
    const distance = pointToLineDistance(newPoint.x, newPoint.y, point1.x, point1.y, point2.x, point2.y);
    if (distance < tolerance) {
      return true;
    }
  }

  return false;
}

function pointToLineDistance(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;
  const dot = A * C + B * D;
  const lenSq = C * C + D * D;
  const param = lenSq !== 0 ? dot / lenSq : -1;
  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
}
