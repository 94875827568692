import React from "react";
import styles from "./EditImageMenu.module.css";
import { ObjectActionsType, useObjectsState, useObjectsDispatch } from "../../../contexts/ObjectsProvider";
import { Section, FramedInput } from "../../../panels/ObjectPropertiesPanel";
import { ensureInBounds, viewBoxZoomToZoomRatio, zoomRatioToViewBoxZoom } from "../../../utils";
import { useAnimatedObject } from "../../../hooks/useAnimatedObject";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useObjectIsInTime } from "../../../hooks/useObjectIsInTime";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { useViewBox } from "../../../hooks/useViewBox";

interface Props {
  isCropping: boolean;
  isCroppedImage: boolean;
  startCropping: () => void;
  restoreImage: () => any;
  nodeToUpdate: any;
  endActions?: any;
  target?: any;
  hidden?: boolean;
}

const ZoomingAndCroppingMenu = (props: Props) => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectState = useSelectedObjectState();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const selectedObject = objectsState.selectedObjects[0];
  const objectId = selectedObject?.objectId;
  const objectInTimeline = useObjectIsInTime(objectId);
  const isCroppedImage = selectedObject?.viewBoxZoom !== 0 || selectedObject.clipPath ? true : false;
  const isCropping = objectsState.isCropping;
  const zoom = selectedObjectState?.viewBoxZoom?.toFixed(2) || 0;
  const originalViewBox = selectedObject?.viewBox;
  const viewBox = selectedObject?.viewBox;
  const originX = selectedObject?.viewBoxX || 0;
  const originY = selectedObject?.viewBoxY || 0;
  const animatedObject = useAnimatedObject(objectId);
  const [tl] = useTimeline();
  const currentTime = tl.scrubbingCurrentTime;
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);
  const zoomHovered = typeof frameAtCurrentTime?.viewBoxZoom === "number";
  const xHovered = typeof frameAtCurrentTime?.viewBoxX === "number";
  const yHovered = typeof frameAtCurrentTime?.viewBoxY === "number";
  const buttonText = isCropping ? "Done Cropping" : "Crop Image";
  const {
    viewBoxZoom: interpolatedViewBoxZoom,
    viewBoxX: interpolatedViewBoxX,
    viewBoxY: interpolatedViewBoxY,
  } = useViewBox(objectId);
  // selector for the data-attribute
  const svgSelector = `div[data-objectid="${selectedObject.objectId}"] svg`;
  return (
    <div hidden={props.hidden}>
      <Section title={"Zoom"} wrap>
        <FramedInput
          value={Math.round(interpolatedViewBoxZoom * 100)}
          inputType="rangeWide"
          rangeMin={0}
          rangeMax={100}
          isHovered={zoomHovered}
          onFrameAdd={() => {
            const newFrame = {
              timestamp: currentTime,
              viewBoxZoom: zoom / 1,
            };
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: newFrame,
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "viewBoxZoom",
              },
            });
          }}
          onInputChange={(event) => {
            const z = parseFloat(event.target.value);
            if (z >= 99.9) return;
            const [, , viewBoxW, viewBoxH] = originalViewBox.split(" ").map(Number);
            // divide by a hundred to get 0 to 1
            const ratio = viewBoxZoomToZoomRatio(z / 100);

            const svg = document.querySelector<SVGSVGElement>(svgSelector);
            if (!svg) return;
            const bbox = svg.getBoundingClientRect();

            // Calculate the center coordinates
            const centerX = bbox.left + bbox.width / 2;
            const centerY = bbox.top + bbox.height / 2;
            let pt = new DOMPoint(centerX, centerY);
            pt = pt.matrixTransform(svg.getScreenCTM()?.inverse());
            const xProportionW = 0.5; // 0.5 is the center
            const yProportionH = 0.5; // 0.5 is the center
            const w2 = viewBoxW * ratio;
            const h2 = viewBoxH * ratio;
            const newX = pt.x - xProportionW * w2;
            const newY = pt.y - yProportionH * h2;
            const { x: newX2, y: newY2 } = ensureInBounds(newX, newY, w2, h2, viewBoxW, viewBoxH);
            objectsDispatch({
              type: ObjectActionsType.SET_VIEW_BOX,
              payload: {
                objectId: selectedObject.objectId,
                zoom: z / 100,
                x: newX2,
                y: newY2,
              },
            });
            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_VIEWBOX,
              payload: {
                zoom: z / 100,
                x: newX2,
                y: newY2,
              },
            });

            if (objectInTimeline) {
              // create a frame with the new pitch value
              const newFrame: {
                timestamp: number;
                viewBox: string;
                viewBoxX?: number;
                viewBoxY?: number;
                viewBoxZoom?: number;
              } = {
                timestamp: currentTime,
                viewBox: originalViewBox,
              };
              if (typeof newX2 === "number") newFrame.viewBoxX = newX2;
              if (typeof newY2 === "number") newFrame.viewBoxY = newY2;
              if (typeof ratio === "number") newFrame.viewBoxZoom = zoomRatioToViewBoxZoom(ratio);
              else {
                newFrame.viewBoxZoom = z / 100;
              }
              console.log("newFrame", newFrame);
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: objectId,
                  frame: newFrame,
                },
              });
            }
          }}
        />
      </Section>
      <Section title={"Origin"}>
        <FramedInput
          label="X"
          inputType="number"
          value={Math.trunc(interpolatedViewBoxX)}
          isHovered={xHovered}
          onFrameAdd={() => {
            const newFrame = {
              timestamp: currentTime,
              viewBoxX: originX,
            };
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: newFrame,
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "viewBoxX",
              },
            });
          }}
          onInputChange={(event) => {
            console.log("originX", event.target.value);
            const newOriginX = parseFloat(event.target.value);
            objectsDispatch({
              type: ObjectActionsType.SET_VIEW_BOX,
              payload: {
                objectId: selectedObject.objectId,
                zoom: zoom,
                x: newOriginX,
                y: originY,
              },
            });

            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_VIEWBOX,
              payload: {
                zoom: zoom,
                x: newOriginX,
                y: originY,
              },
            });

            if (objectInTimeline) {
              const newFrame = {
                timestamp: currentTime,
                viewBox: originalViewBox,
                viewBoxX: newOriginX, // Only update X
              };
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: objectId,
                  frame: newFrame,
                },
              });
            }
          }}
        />
        <FramedInput
          label="Y"
          inputType="number"
          value={Math.trunc(interpolatedViewBoxY)}
          isHovered={yHovered}
          onFrameAdd={() => {
            const newFrame = {
              timestamp: currentTime,
              viewBoxY: originY,
            };
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: newFrame,
              },
            });
          }}
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "viewBoxY",
              },
            });
          }}
          onInputChange={(event) => {
            const newOriginY = parseFloat(event.target.value);
            objectsDispatch({
              type: ObjectActionsType.SET_VIEW_BOX,
              payload: {
                objectId: selectedObject.objectId,
                zoom: zoom,
                x: originX,
                y: newOriginY,
              },
            });

            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_VIEWBOX,
              payload: {
                zoom: zoom,
                x: originX,
                y: newOriginY,
              },
            });

            if (objectInTimeline) {
              // create a frame with the new pitch value
              const newFrame = {
                timestamp: currentTime,
                viewBox: originalViewBox,
                viewBoxY: newOriginY, // Only update Y
              };
              objectsDispatch({
                type: ObjectActionsType.UPSERT_OBJECT_FRAME,
                payload: {
                  objectId: objectId,
                  frame: newFrame,
                },
              });
            }
          }}
        />
      </Section>
      <Section title={"Crop"}>
        <FramedInput label="W" inputType="number" svgShowing={false} />
        <FramedInput label="H" inputType="number" svgShowing={false} />
      </Section>

      <div className={styles.editImageButtonContainer}>
        <button
          className={`${styles.editImageButton} ${isCropping ? styles.imageEditButtonActive : ""}`}
          id={styles.cropImageButton}
          onClick={() => {
            objectsDispatch({
              type: ObjectActionsType.TOGGLE_IS_CROPPING,
            });
          }}
        >
          {buttonText}
        </button>
        <div className={styles.editImageButtonSeparator}></div>
        {isCroppedImage && (
          <button
            className={styles.editImageButton}
            onClick={() => {
              objectsDispatch({
                type: ObjectActionsType.REMOVE_ZOOM,
                payload: {
                  objectId: selectedObject.objectId,
                },
              });
              objectsDispatch({
                type: ObjectActionsType.RESTORE_CROPPED_IMAGE,
                payload: {
                  objectId: selectedObject.objectId,
                },
              });
            }}
          >
            Restore Image
          </button>
        )}
      </div>
    </div>
  );
};

export default ZoomingAndCroppingMenu;
