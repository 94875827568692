import { SVG_SMART_OBJECT_TYPES, SmartObjectElement } from ".";

export const getSmartObjectMetaVariableKey = (objectId: string | undefined, groupId: string | undefined) => {
  if (!objectId || !groupId) {
    return "";
  }

  return `${objectId}-${groupId}`;
};

export const getHotSpotMetaVariablesKeys = (
  objectId: string | undefined,
  hotSpotId: string | undefined,
  setId: string | undefined,
) => {
  if (!objectId || !setId || !hotSpotId) {
    return {
      target: "",
      display: "",
      enabled: "",
    };
  }
  const target = getSmartObjectMetaVariableKey(objectId, setId);
  const baseKey = `${objectId}-${hotSpotId}`;

  return {
    target,
    display: `${baseKey}-display`,
    enabled: `${baseKey}-enabled`,
  };
};

export const getRotationDegreeFromValue = ({
  currentValue,
  minValue,
  maxValue,
  minDegree,
  maxDegree,
}: {
  currentValue: number;
  minValue?: number;
  maxValue?: number;
  minDegree?: number;
  maxDegree?: number;
}) => {
  // Assuming linear interpolation
  if (minValue === undefined || maxValue === undefined || minDegree === undefined || maxDegree === undefined) {
    return;
  }

  const fraction = (currentValue - minValue) / (maxValue - minValue);
  const degree = minDegree + fraction * (maxDegree - minDegree);
  return degree;
};

export const getXAndYFromRotateChildren = (children: SmartObjectElement[] | undefined) => {
  if (!children) return null;

  const coordinates = {
    x: 0,
    y: 0,
  };

  for (const child of children) {
    if (child.TYPE === SVG_SMART_OBJECT_TYPES.ROTATE_REF) {
      const width = Number(child.width);
      const height = Number(child.height);
      const x = Number(child.x);
      const y = Number(child.y);

      const finalX = x + width / 2;
      const finalY = y + height / 2;

      coordinates.x = finalX;
      coordinates.y = finalY;
      break;
    }
  }

  return coordinates;
};

export function getSmartObjectViewBox(smartObject: any) {
  if (!smartObject || smartObject.type !== "svg" || !smartObject.viewBox) {
    throw new Error("Invalid smart object or missing viewBox");
  }

  try {
    const viewBox = smartObject.viewBox.split(" ").map(parseFloat);
    const viewBoxWidth = viewBox[2];
    const viewBoxHeight = viewBox[3];
    if (viewBoxWidth === 0 || viewBoxHeight === 0) {
      throw new Error("ViewBox dimensions cannot be zero");
    }

    return viewBox;
  } catch (error) {
    console.error("Error getting viewBox: ", error);
    return undefined; // or you might choose to rethrow the error or handle it differently
  }
}

export const getSmartObjectAspectRatio = (smartObject: any) => {
  if (!smartObject || smartObject.type !== "svg" || !smartObject.viewBox) {
    throw new Error("Invalid smart object or missing viewBox");
  }

  try {
    const viewBox = getSmartObjectViewBox(smartObject);
    const viewBoxWidth = viewBox[2];
    const viewBoxHeight = viewBox[3];
    const aspectRatio = viewBoxWidth / viewBoxHeight;
    return aspectRatio;
  } catch (error) {
    console.error("Error calculating aspect ratio: ", error);
    return undefined; // or you might choose to rethrow the error or handle it differently
  }
};

export const cleanSmartObjectText = (textContent: string | undefined) => {
  if (!textContent) return "";
  return textContent.replace(/\n\s*/g, "");
};

export const getMetaVariableNameFromKey = (key: string | undefined, objectId: string | undefined) => {
  if (!key || !objectId) return "";
  const name = key.replace(objectId, "");
  return name.toLowerCase().replaceAll("_", " ").replaceAll("-", " ");
};

export const getSmartObjectName = (smartObject: any): string | null => {
  if (!smartObject && !smartObject.NAME) return null;

  if (smartObject.NAME && typeof smartObject.NAME === "string") {
    return smartObject.NAME;
  }

  return null;
};

export const getHotspotFreezeTimelineKey = (objectId: string | undefined) => {
  if (!objectId) {
    return {
      base: "",
      key: "",
    };
  }

  return {
    base: `${objectId}-hotspot`,
    key: `${objectId}-hotspot-freeze-timeline`,
  };
};
