import {
  InteractivityHotspotActionTypes,
  useInteracitvityHotspotDispatch,
} from "../../contexts/InteractivityHotspotProvider";
import { useObjectsState, ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import { ReactComponent as Icon } from "../../assets/icons/AssetManager/smart-object.svg";
import styles from "./SmartObject.module.css";
import { SmartObjectRenderer } from "../../lib/SmartObject/components/SmartObjectRenderer";
import React, { useEffect, useMemo } from "react";
import { objectOpacityFromRules } from "../../utils";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";

interface SmartObjectsProps {
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}

export const SmartObjects = ({ handleDoubleClick }: SmartObjectsProps) => {
  const { smartObjects } = useObjectsState();

  return (
    <>
      {smartObjects.map((item) => {
        console.log("item", item);
        return <SmartObject key={item.objectId} handleDoubleClick={handleDoubleClick} {...item} />;
      })}
    </>
  );
};

interface SmartObjectProps {
  objectId: string;
  left: number;
  top: number;
  width: number;
  height: number;
  ghost?: boolean;
  /**@deprecated */
  rotate?: number;
  rotation?: number;
  opacity?: number;
  pitch?: number;
  yaw?: number;
  isDisplayed?: boolean;
  zIndex?: number;
  elementList?: any;
  clipPath?: {
    absoluteTop: number;
    absoluteLeft: number;
    absoluteRight: number;
    absoluteBottom: number;
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  handleDoubleClick: (e: React.MouseEvent<HTMLDivElement | HTMLVideoElement>) => void;
}

const SmartObject = ({
  objectId,
  left,
  top,
  width,
  height,
  rotation,
  opacity,
  zIndex,
  elementList,
  isDisplayed,
  ghost = false,
  clipPath,
  handleDoubleClick,
  clipPathString,
  transformOrigin,
}: SmartObjectProps) => {
  const {
    position: [x, y],
    rotation: finalRotation,
    size: [widthWithFrames, heightWithFrames],
    opacity: opacityValue,
  } = useObjectPosition(objectId, top, left, width, height, rotation, opacity);
  const objectDispatch = useObjectsDispatch();
  const objectsState = useObjectsState();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();

  const rotateString = typeof finalRotation === "number" ? `rotate(${finalRotation}deg)` : "rotate(0deg)";

  console.log("X", x, "Y", y);
  const transform = `translate(${x}px, ${y}px) ${rotateString}`;
  const transformOriginString = transformOrigin ? `${transformOrigin.x}% ${transformOrigin.y}%` : undefined;
  const isInTime = useObjectIsInTime(objectId);
  const finalOpacity = objectOpacityFromRules(opacityValue, isDisplayed, isInTime, ghost);
  const isCroppedImage = clipPathString ? true : false;
  const isCropping = objectsState.isCropping;
  const smartObject = useMemo(() => {
    return {
      objectId,
      height: heightWithFrames,
      width: widthWithFrames,
      left,
      top,
      elementList,
    };
  }, [objectId, heightWithFrames, widthWithFrames, top, left]);

  const {
    top: topFromClipPath,
    right: rightFromClipPath,
    bottom: bottomFromClipPath,
    left: leftFromClipPath,
  } = clipPath ?? {};

  const clipContainerLeft = leftFromClipPath;
  const clipContainerTop = topFromClipPath;
  const clipContainerWidth = 100 - leftFromClipPath - rightFromClipPath;
  const clipContainerHeight = 100 - topFromClipPath - bottomFromClipPath;

  const shouldContainerBeMoveable = (() => {
    if (isCroppedImage && isCropping) {
      objectsState.moveableRef?.updateRect();
      return true;
    }
    if (isCroppedImage) {
      objectsState.moveableRef?.updateRect();
      return false;
    }
    return true;
  })();

  useEffect(() => {
    if (shouldContainerBeMoveable) {
      console.log("effy", objectsState.moveableRef);
      setTimeout(() => {
        console.log("updating rect:");
        const t = objectsState.moveableRef?.updateRect();

        console.log("t", t);
        objectDispatch({
          type: ObjectActionsType.SET_SELECTED_OBJECT,
          payload: { objectId },
        });
      }, 300);
    }
  }, [shouldContainerBeMoveable, objectsState.moveableRef]);

  console.log("shouldContainerBeMoveable", shouldContainerBeMoveable);
  return (
    <div
      className={elementList ? "" : styles.empty}
      data-objectId={shouldContainerBeMoveable ? objectId : ""}
      data-ref={objectId}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        opacity: finalOpacity,
        zIndex,
        transform,
        transformOrigin: transformOriginString,
        rotate: `${rotateString}`,
        display: isInTime || ghost ? undefined : "none",
        clipPath: isCroppedImage ? clipPathString : "",
      }}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectDispatch({
          type,
          payload: { objectId },
        });
      }}
      onDoubleClick={(e: React.MouseEvent<HTMLDivElement>) => handleDoubleClick(e)}
    >
      {elementList ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <SmartObjectRenderer smartObject={smartObject} />
            {!shouldContainerBeMoveable && (
              <CroppedMoveableContainer
                objectId={objectId}
                clipContainerTop={clipContainerTop}
                clipContainerLeft={clipContainerLeft}
                clipContainerWidth={clipContainerWidth}
                clipContainerHeight={clipContainerHeight}
                shouldContainerBeMoveable={shouldContainerBeMoveable}
              />
            )}
          </div>
        </>
      ) : (
        <span className={styles.icon}>
          <Icon
            style={{
              width: "80%",
              height: "80%",
            }}
          />
        </span>
      )}
    </div>
  );
};

export function CroppedMoveableContainer({
  objectId,
  clipContainerTop,
  clipContainerLeft,
  clipContainerWidth,
  clipContainerHeight,
  shouldContainerBeMoveable,
}: {
  objectId: string;
  clipContainerTop: number;
  clipContainerLeft: number;
  clipContainerWidth: number;
  clipContainerHeight: number;
  shouldContainerBeMoveable: boolean;
}) {
  const objectDispatch = useObjectsDispatch();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  return (
    <div
      data-objectId={!shouldContainerBeMoveable ? objectId : ""}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectDispatch({
          type,
          payload: { objectId },
        });
      }}
      style={{
        // pointerEvents: "none",
        position: "absolute",
        height: clipContainerHeight + "%",
        width: clipContainerWidth + "%",
        top: clipContainerTop + "%",
        left: clipContainerLeft + "%",
      }}
    ></div>
  );
}
