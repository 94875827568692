import { useEffect, useRef } from "react";
import PageEditorActionLister from "../../components/PageActionLister/PageEditorActionLister";
import "./RightPanel.css";
import { Properties } from "./Properties";
import { BaseObject } from "../../types";
import {
  LessonPagesActions,
  useLessonPagesDispatch,
  useLessonPagesState,
} from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { AdvancedPanel } from "../Advanced";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { OBJECT_TYPE_SHAPE } from "../../const";

interface RightPanelProps {
  lessonPages: any[];
  currentlyDisplayedPageIndex: number;
  lessonVersionId: number;
  isModalShown: boolean;
}

const selectedPanelButtonStyle = {
  background: "var(--buttons-color)",
  color: "white",
};
const nonSelectedPanelButtonStyle = {
  cursor: "pointer",
};

function RightPanel({ currentlyDisplayedPageIndex, lessonPages, lessonVersionId, isModalShown }: RightPanelProps) {
  const lessonPagesDispatch = useLessonPagesDispatch();
  const { selectedPanel } = useLessonPagesState();
  const { selectedObjects } = useObjectsState();
  const previouslySelectedObject = useRef<string | null>(null);
  const [, setMiscUI] = useMiscUI();

  const selectedObject: BaseObject | undefined = selectedObjects[0];
  const isSmartObject = selectedObject?.type === "smartObject";
  const isPanoramic = selectedObject?.type === "panoramic";
  const isShape = selectedObject?.type === OBJECT_TYPE_SHAPE;
  const isImage = selectedObject?.type === "pageImage";

  const hasAdvancedPanel = isPanoramic || isSmartObject || isShape;

  /**
   * This useEffect works so that if another object is selected
   * and the panel is in the `advanced` tab it will switch to
   * the properties tab
   */
  useEffect(() => {
    setMiscUI({ type: "OBJECT_LOCKED", payload: selectedPanel === "advanced" });

    if (selectedPanel === "properties") {
      setMiscUI({
        type: "SET_SELECTED_MASK_ID",
        payload: null,
      });
    }

    if (selectedObject && !previouslySelectedObject.current) {
      previouslySelectedObject.current = selectedObject.objectId;
      return;
    }
    if (selectedPanel !== "advanced") return;
    if (!selectedObject || previouslySelectedObject.current !== selectedObject.objectId) {
      previouslySelectedObject.current = null;
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_SELECTED_PANEL,
        payload: "properties",
      });
    }
  }, [selectedObject?.objectId, JSON.stringify(previouslySelectedObject.current), selectedPanel]);

  const handleTabClick = (payload: "properties" | "page" | "advanced") => {
    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_PANEL,
      payload,
    });
  };

  return (
    <div className="right-panel">
      <div className="selection-container">
        <div
          style={selectedPanel === "page" ? selectedPanelButtonStyle : nonSelectedPanelButtonStyle}
          onClick={() => {
            handleTabClick("page");
            lessonPagesDispatch({
              type: LessonPagesActions.UPDATE_SELECTED_PANEL,
              payload: "page",
            });
          }}
        >
          Page
        </div>
        <div
          style={selectedPanel === "properties" ? selectedPanelButtonStyle : nonSelectedPanelButtonStyle}
          onClick={() => {
            handleTabClick("properties");
            lessonPagesDispatch({
              type: LessonPagesActions.UPDATE_SELECTED_PANEL,
              payload: "properties",
            });
          }}
        >
          Properties
        </div>
        {hasAdvancedPanel && (
          <div
            style={selectedPanel === "advanced" ? selectedPanelButtonStyle : nonSelectedPanelButtonStyle}
            onClick={() => {
              lessonPagesDispatch({
                type: LessonPagesActions.UPDATE_SELECTED_PANEL,
                payload: "advanced",
              });
            }}
          >
            Advanced
          </div>
        )}
      </div>
      <div className="selected-container" style={{ overflow: isImage || isSmartObject ? "auto" : "hidden" }}>
        {selectedPanel === "page" && (
          <PageEditorActionLister
            lessonPages={lessonPages}
            currentIndex={currentlyDisplayedPageIndex}
            lessonVersionId={lessonVersionId}
          />
        )}
        {selectedPanel === "properties" && <Properties isModalShown={isModalShown} />}
        {selectedPanel === "advanced" && <AdvancedPanel />}
      </div>
    </div>
  );
}

export default RightPanel;
