/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  SmartObjectComponent,
  SmartObjectLine,
  SmartObjectPolygon,
  SmartObjectPolyline,
  SmartObjectSvgPath,
  SmartObjectSvgRendererProps,
  SmartObjectTSpan,
  SmartObjectText,
  SmartObjectImage,
} from "../";
import { useMetaVariableStore } from "../../../store";
import { getRotationDegreeFromValue, getSmartObjectMetaVariableKey, getXAndYFromRotateChildren } from "../../../utils";
import { useSmartObjectStyles } from "../useSmartObjectStyles";
export function SvgRotateRenderer({ element, objectId, parentId }: SmartObjectSvgRendererProps) {
  const { type, GROUP_ID, ACTION, HOTSPOT_ID, TYPE, VALUE, children, textContent, ...rest } = element;

  switch (type) {
    case "rect":
      return <rect {...rest} style={{ fill: "white", fillOpacity: 0, strokeOpacity: 0 }} />;
    case "g":
      return <SvgRotateG element={element} objectId={objectId} parentId={parentId} />;
    case "polyline": {
      return <SmartObjectPolyline element={element} />;
    }
    case "polygon": {
      return <SmartObjectPolygon element={element} />;
    }
    case "text": {
      return <SmartObjectText element={element} />;
    }
    case "tspan": {
      return <SmartObjectTSpan element={element} />;
    }
    case "line": {
      return <SmartObjectLine element={element} />;
    }
    case "path": {
      return <SmartObjectSvgPath element={element} />;
    }
    case "image": {
      return <SmartObjectImage element={element} />;
    }
    default: {
      console.warn("SvgRotateRenderer: type not handled", type);
      return null;
    }
  }
}

export function SmartObjectSvgRotate({ element, objectId }: SmartObjectComponent) {
  console.log("SmartObjectSvgRotate", element);
  const { children, ACTION, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, SET_ID, ...rest } = element;
  const key = getSmartObjectMetaVariableKey(objectId, GROUP_ID);
  const currentValue = useMetaVariableStore((s) => s.metaVariables[key] as number);
  const metaData = useMetaVariableStore((s) => s.metaVariablesData[key]);
  const styles = useSmartObjectStyles(element.style);

  const degree = getRotationDegreeFromValue({
    currentValue,
    minValue: metaData?.minValue,
    maxValue: metaData?.maxValue,
    minDegree: metaData?.minDegree,
    maxDegree: metaData?.maxDegree,
  });

  const coordinates = getXAndYFromRotateChildren(children);
  const transform = coordinates ? `rotate(${degree}, ${coordinates.x}, ${coordinates.y})` : `rotate(${degree})`;

  return (
    <g {...rest} transform={transform} style={styles}>
      {children?.map((item, idx) => (
        <SvgRotateRenderer key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}

export function SvgRotateG({ element, objectId }: SmartObjectComponent) {
  const styles = useSmartObjectStyles(element.style);
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, ...rest } = element;

  return (
    <g {...rest} style={styles}>
      {children?.map((item, idx) => (
        <SvgRotateRenderer key={idx} element={item} objectId={objectId} />
      ))}
    </g>
  );
}
