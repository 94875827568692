import { CSSProperties, useRef, useState } from "react";
import { SmartObjectHotspot, useMetaVariableStore } from "../../../store";
import { Separator } from "../../../../../panels/ObjectPropertiesPanel";
import styles from "./AdvancedPanel.module.css";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/arrow-down-black.svg";
import { FillInput } from "./components/Fill";
import { useAdvancedPanelData } from "./useAdvancePanelData";
import { Text } from "./components/Text";
import { ImageSwap } from "./components/ImageSwap";
import { Rotate } from "./components/Rotate";
import { ObjectActionsType, useObjectsDispatch } from "../../../../../contexts/ObjectsProvider";
import { ImageText } from "./components/ImageText";
import { HotspotFreeze } from "./components/HotspotFreeze";
interface SmartObjectAdvancedPanelProps {
  objectId: string;
}

export type RotatePanel = {
  name: string;
  minValue: number;
  maxValue: number;
  minDegree: number;
  maxDegree: number;
  key: string;
};

export type ImageSwapPanel = {
  name: string;
  choices: string[] | number[] | boolean[];
  key: string;
  hotspots: SmartObjectHotspot[];
  animate: boolean;
  rate: number;
  hide: boolean;
};

export type Fill = {
  name: string;
  choices: string[];
  key: string;
};

export type TextPanel = {
  name: string;
  key: string;
};

export type ImageTextPanel = {
  name: string;
  key: string;
  font: string;
  stack: string;
  justify: string;
};

export const SmartObjectAdvancedPanel = ({ objectId }: SmartObjectAdvancedPanelProps) => {
  const { imageSwap, fill, rotate, texts, imageTexts } = useAdvancedPanelData(objectId);
  const objectsDispatch = useObjectsDispatch();

  const handleChange = () => {
    objectsDispatch({ type: ObjectActionsType.SET_NEED_SAVE });
  };

  return (
    <>
      <Separator title="Panel Value Setup" />
      <div className={styles.groupContainer}>
        <HotspotFreeze objectId={objectId} />

        {texts.map((data) => {
          return <Text key={data.key} data={data} onChange={handleChange} />;
        })}

        {fill.map((data) => {
          return <FillInput data={data} key={data.key} onChange={handleChange} />;
        })}

        {rotate.map((data) => {
          return <Rotate data={data} key={data.key} onChange={handleChange} />;
        })}

        {imageSwap.map((data) => {
          return <ImageSwap key={data.key} data={data} onChange={handleChange} />;
        })}

        {imageTexts.map((data) => {
          return <ImageText key={data.key} data={data} />;
        })}
      </div>
    </>
  );
};

interface SelectPanelProps {
  data: ImageSwapPanel | Fill;
  handleChange: (key: string, value: any) => void;
  handleAnimate?: () => void;
  selectedValue: string;
}

export const SelectPanel = ({ selectedValue, data, handleChange, handleAnimate }: SelectPanelProps) => {
  const metaVariablesData = useMetaVariableStore((s) => s.metaVariablesData[data.key]);
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const animate = (data as ImageSwapPanel)?.animate ?? false;
  useClickOutside(ref, () => setOpen(false));
  const objectsDispatch = useObjectsDispatch();

  const arrowStyles = {
    transform: open ? "rotate(180deg)" : "",
    transition: "all 150ms ease-out",
  } as CSSProperties;

  const handleNeedSave = () => {
    objectsDispatch({ type: ObjectActionsType.SET_NEED_SAVE });
  };

  return (
    <div className={styles.selectPanelWrapper} ref={ref}>
      <button className={styles.selectTrigger} onClick={() => setOpen((v) => !v)}>
        {animate && metaVariablesData?.isAnimating ? "Animate" : selectedValue}
        <ArrowDown style={arrowStyles} />
      </button>
      {open && (
        <div className={styles.selectOptions}>
          {data?.choices?.map((item, idx) => {
            return (
              <button
                key={idx}
                className={styles.selectOption}
                onClick={() => {
                  handleChange(data.key, item);
                  handleNeedSave();
                  setOpen(false);
                }}
              >
                {item}
              </button>
            );
          })}
          {(data as ImageSwapPanel)?.animate && (
            <button
              className={styles.selectOption}
              onClick={() => {
                handleAnimate?.();
                handleNeedSave();
                setOpen(false);
              }}
            >
              Animate
            </button>
          )}
          {(data as ImageSwapPanel)?.hide && (
            <button
              className={styles.selectOption}
              onClick={() => {
                handleChange(data.key, "hide");
                handleNeedSave();
                setOpen(false);
              }}
            >
              Hide
            </button>
          )}
        </div>
      )}
    </div>
  );
};
