import { useEffect, useRef } from "react";
import { ensureInBounds } from "../../../../utils";
import { useObjectsState } from "../../../../contexts/ObjectsProvider";

export function useSvgZoomAndDrag({
  svgRef,
  startingViewBox,
  onViewBoxChange,
}: {
  svgRef: SVGSVGElement | null;
  startingViewBox?: string;
  onViewBoxChange?: (viewBox: { viewBox: string; ratio: number | undefined; x: number; y: number }) => void;
}) {
  const mouseDown = useRef(false);
  const objectsState = useObjectsState();

  useEffect(() => {
    if (!svgRef) return;
    if (!startingViewBox) return;
    const [, , initialW, initialH] = startingViewBox.split(" ").map(Number);
    const initialViewBoxWidth = initialW;
    const initialViewBoxHeight = initialH;

    const handleDocumentMouseUp = () => {
      mouseDown.current = false;
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
    const handleDocumentMouseMove = (e: MouseEvent) => {
      if (!mouseDown.current) return;
      const { x, y, width, height } = svgRef.viewBox.baseVal;
      // check if the viewbox is not over the svg
      const movementFactor = 1.5;
      const { x: newX, y: newY } = ensureInBounds(
        x + e.movementX * -1 * movementFactor,
        y + e.movementY * -1 * movementFactor,
        width,
        height,
        initialViewBoxWidth,
        initialViewBoxHeight,
      );
      onViewBoxChange?.({
        viewBox: `${newX} ${newY} ${width} ${height}`,
        ratio: undefined,
        x: newX,
        y: newY,
      });
    };
    const handleMouseDown = () => {
      mouseDown.current = true;
      document.addEventListener("mouseup", handleDocumentMouseUp);
    };
    const handleMouseUp = () => {
      mouseDown.current = false;
    };

    const handleMouseLeave = () => {
      // mouseDown.current = false;
      document.addEventListener("mousemove", handleDocumentMouseMove);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!mouseDown.current) return;
      const { x, y, width, height } = svgRef.viewBox.baseVal;
      const movementFactor = 1.5;
      const { x: newX, y: newY } = ensureInBounds(
        x + e.movementX * -1 * movementFactor,
        y + e.movementY * -1 * movementFactor,
        width,
        height,
        initialViewBoxWidth,
        initialViewBoxHeight,
      );

      onViewBoxChange?.({
        viewBox: `${newX} ${newY} ${width} ${height}`,
        ratio: undefined,
        x: newX,
        y: newY,
      });
    };
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      if (event.deltaY === 0) return;
      const deltaY = Math.max(-100, Math.min(100, event.deltaY));
      // set the scaling factor (and make sure it's at least 10%)
      let scale = deltaY / 1000;
      scale = Math.abs(scale) < 0.1 ? (0.1 * deltaY) / Math.abs(deltaY) : scale;
      // get point in SVG space
      let pt = new DOMPoint(event.clientX, event.clientY);
      pt = pt.matrixTransform(svgRef.getScreenCTM().inverse());
      // get viewbox transform
      const [x, y, width, height] = svgRef.getAttribute("viewBox").split(" ").map(Number);
      // get pt.x as a proportion of width and pt.y as proportion of height
      const [xPropW, yPropH] = [(pt.x - x) / width, (pt.y - y) / height];
      // calc new width and height, new x2, y2 (using proportions and new width and height)
      let [width2, height2] = [width + width * scale, height + height * scale];
      if (width2 < 0) {
        width2 = 0;
      }
      if (height2 < 0) {
        height2 = 0;
      }
      if (width2 > initialViewBoxWidth) {
        width2 = initialViewBoxWidth;
      }
      if (height2 > initialViewBoxHeight) {
        height2 = initialViewBoxHeight;
      }
      let x2 = pt.x - xPropW * width2;
      let y2 = pt.y - yPropH * height2;

      //check if the viewbox is not over the svg
      if (x2 + width2 > initialViewBoxWidth) {
        x2 = initialViewBoxWidth - width2;
      }
      // check if the viewbox is not over the svg
      if (y2 + height2 > initialViewBoxHeight) {
        y2 = initialViewBoxHeight - height2;
      }

      // check if the viewbox is not over the svg
      if (x2 < 0) {
        x2 = 0;
      }
      // check if the viewbox is not over the svg
      if (y2 < 0) {
        y2 = 0;
      }

      // we are calculating the ratio since the height ratio and width ratio are the same
      const ratio = width2 / initialViewBoxWidth;
      onViewBoxChange?.({
        viewBox: `${x2} ${y2} ${width2} ${height2}`,
        ratio,
        x: x2,
        y: y2,
      });
    };
    if (objectsState.isCropping) {
      svgRef.addEventListener("mousedown", handleMouseDown);
      svgRef.addEventListener("mouseup", handleMouseUp);
      svgRef.addEventListener("mousemove", handleMouseMove);
      svgRef.addEventListener("wheel", handleWheel);
      svgRef.addEventListener("mouseleave", handleMouseLeave);
    } else {
      svgRef.removeEventListener("mousedown", handleMouseDown);
      svgRef.removeEventListener("mouseup", handleMouseUp);
      svgRef.removeEventListener("mousemove", handleMouseMove);
      svgRef.removeEventListener("wheel", handleWheel);
      svgRef.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mousemove", handleDocumentMouseMove);
    }

    return () => {
      svgRef.removeEventListener("mousedown", handleMouseDown);
      svgRef.removeEventListener("mouseup", handleMouseUp);
      svgRef.removeEventListener("mousemove", handleMouseMove);
      svgRef.removeEventListener("wheel", handleWheel);
      svgRef.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mousemove", handleDocumentMouseMove);
    };
  }, [svgRef, onViewBoxChange, objectsState.isCropping]);
}
